import { useQuery } from "@tanstack/react-query";
import { useMemo, useState } from 'react';

const fetchArtStyles = async (pageParam = 1) => {
    const { data } = await window.axios.get(route("artstyles.paginate", { active: 1, per_page: 100, page: pageParam }))
    return data;
}

export const useArtStyles = () => {
    const [page, setPage] = useState(1);
    const { data, isLoading } = useQuery({
        queryKey: ['active-art-styles', page],
        queryFn: () => fetchArtStyles(page),
        refetchOnWindowFocus: false,
        keepPreviousData: true,
    })

    const items = useMemo(() => data?.data || [], [data]);
    const links = useMemo(() => data?.links || [], [data]);

    return { items, isLoading, links, page, setPage }
}
