import { Button } from "@/Components/ui/button";
import { Card, CardContent } from "@/Components/ui/card";
import { ScrollArea } from "@/Components/ui/scroll-area";
import { Sheet, SheetContent, SheetDescription, SheetHeader, SheetTitle } from "@/Components/ui/sheet";
import { cn } from "@/lib/utils";
import { Icon } from "@iconify/react";
import { Link, usePage } from "@inertiajs/react";
import { useGlobalModals } from "@state/store";
import { useInfiniteQuery, useQueryClient } from "@tanstack/react-query";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import Empty from "./Empty";
import ApplicationLogo from "@/Components/ApplicationLogo";
import TruncatedText from "./TruncatedText";
import { JSONPostRequest } from "@/Hooks/JSONRequest";
import { navigate, setLoader } from "@/Hooks/Functions";
import { ConfirmDialog } from "@/lib/mixins";
import { toast } from "sonner";

const EachItem = React.memo(({ item, onSelect }) => {
    const message = useMemo(() => item.data?.message, [item]);
    const humanizeDate = useMemo(() => {
        const date = new Date(item.created_at);
        return date.toLocaleString('en-US', {
            month: 'short',
            day: 'numeric',
            year: 'numeric',
            hour: 'numeric',
            minute: '2-digit',
            hour12: true
        });
    }, [item]);

    return (
        <Card onClick={() => onSelect(item)} className="w-full rounded-none group hover:bg-primary/30 duration-300 transition-all cursor-pointer shadow-xl bg-[#f2f2f2]">
            <CardContent className="p-2 flex relative flex-row isolate items-start gap-y-2 gap-x-3">
                <ApplicationLogo square className="size-20 aspect-square" />
                <div className="flex-1 flex flex-col gap-2">
                    <TruncatedText maxWords={15} className={cn("block text-sm w-full", item.read_at ? "font-normal" : "font-semibold")}>
                        {message}
                    </TruncatedText>
                    <small className="text-xs text-gray-600">{humanizeDate}</small>
                </div>
            </CardContent>
        </Card>
    );
});

const fetchItems = async (page) => {
    const response = await window.axios.get(route('notifications.paginate', { page: page, per_page: 20 }));
    return response.data;
};

export default function NotificationModal({ iconClass }) {
    const { auth } = usePage().props;

    const userId = useMemo(() => auth?.user?.id, [auth?.user?.id]);
    const setNotificationModal = useGlobalModals(state => state.setNotificationModal);
    const notificationModal = useGlobalModals(state => state.notificationModal);

    const queryClient = useQueryClient();

    const { data, isLoading, hasNextPage, fetchNextPage, isFetchingNextPage } = useInfiniteQuery({
        queryKey: ['notifications'],
        queryFn: ({ pageParam = 1 }) => fetchItems(pageParam),
        getNextPageParam: (lastPage) => {
            if (lastPage.current_page < lastPage.last_page) {
                return lastPage.current_page + 1;
            }
            return undefined;
        },
        enabled: !!auth.user
    });

    const items = useMemo(() => {
        return data?.pages.reduce((acc, page) => {
            return [...acc, ...page.data];
        }, []) || [];
    }, [data]);

    const hasUnread = useMemo(() => {
        return items.some((item) => !item.read_at);
    }, [items])

    const loadMore = useCallback(() => {
        if (hasNextPage) {
            fetchNextPage();
        }
    }, [fetchNextPage, hasNextPage]);

    const onReadAll = useCallback(() => {
        JSONPostRequest({
            href: route('notifications.read-all'),
            onResult: () => {
                queryClient.invalidateQueries({ queryKey: ['notifications'] });
                toast.success("All notifications marked as read.")
            },
            onLoading: (val) => setLoader(val)
        })
    }, [setNotificationModal, queryClient]);

    const onSelect = useCallback((item) => {
        if (!item.read_at) {
            JSONPostRequest({
                href: route('notifications.read-one', { id: item.id }),
                onResult: () => queryClient.invalidateQueries({ queryKey: ['notifications'] }),
                onLoading: (val) => console.log(val)
            })
        }
        if (item.data?.link) {
            navigate(item.data?.link, 'GET');
            setNotificationModal(false);
        } else {
            setNotificationModal(false);
            ConfirmDialog.fire({
                title: "Notification",
                html: item.data?.message,
                icon: 'info',
                cancelButtonText: 'Close',
                showConfirmButton: false,
            })
        }
    }, [setNotificationModal, queryClient]);

    useEffect(() => {
        window.Echo.private('App.Models.User.' + userId)
            .notification((notification) => {
                queryClient.invalidateQueries({ queryKey: ['notifications'] })
            });

        return () => {
            window.Echo.leave('App.Models.User.' + userId);
        }
    }, [userId, queryClient])

    return (
        <>
            <Button tip="View Notifications" variant="link" className="!p-1 group" onClick={() => setNotificationModal(true)}>
                <Icon icon="material-symbols:circle-notifications-sharp" className={cn("size-10 group-hover:text-cyan-600 text-primary", iconClass, hasUnread ? "animate-pulse" : "animate-none")} />
            </Button>
            <Sheet open={notificationModal} onOpenChange={() => setNotificationModal(false)} className="isolate">
                {auth?.user ? <SheetContent className=" p-0 flex flex-col gap-4 md:min-w-[450px] min-w-full">
                    <SheetHeader className="gap-2 px-4 pt-2 justify-between flex flex-col !items-center">
                        <SheetTitle className="mr-8 font-nunito italic">Notifications</SheetTitle>
                        <SheetDescription className="w-full gap-2 flex flex-row justify-between items-center">
                            <Button variant="outline" size='xs' className="w-full" onClick={() => {
                                queryClient.invalidateQueries({ queryKey: ['notifications'] });
                            }}>
                                <Icon icon="material-symbols:refresh" className="mr-2" />
                                Refresh
                            </Button>
                            <Button variant="outline" size='xs' className="w-full" onClick={onReadAll}>
                                <Icon icon="icon-park-outline:read-book" className="mr-2" />
                                Mark all as read
                            </Button>
                        </SheetDescription>
                    </SheetHeader>
                    <ScrollArea className="rounded-lg h-[calc(100vh-60px)] text-primary w-full pl-2 pr-4">
                        {isLoading && <div>Loading...</div>}
                        {!isLoading && !items.length && <Empty className="min-h-[80vh]" text="No notification found!" />}
                        {items.map((item) => (
                            <EachItem
                                key={`${item.id}-${item.read_at}`}
                                item={item}
                                onSelect={onSelect}
                            />
                        ))}
                        {hasNextPage && (
                            <Button
                                variant="outline"
                                className="w-full mt-4"
                                onClick={loadMore}
                                disabled={isFetchingNextPage}
                            >
                                {isFetchingNextPage ? 'Loading...' : 'Load More'}
                            </Button>
                        )}
                    </ScrollArea>
                </SheetContent> :
                    <SheetContent className="p-0 flex flex-col gap-4 md:min-w-[450px] min-w-full">
                        <SheetHeader className="gap-4 px-4 py-2 justify-between flex flex-row !items-center">
                            <SheetTitle className="mr-8">Notifications</SheetTitle>
                        </SheetHeader>
                        <ScrollArea className="rounded-lg h-[calc(100vh-60px)] text-primary w-full px-2">
                            <Empty className="min-h-[80vh]" text="Please login to view notifications!" >
                                <Link href={route('login')} >
                                    <Button className="mt-4 px-8">Login</Button>
                                </Link>
                            </Empty>
                        </ScrollArea>
                    </SheetContent>}
            </Sheet>
        </>
    );
}
