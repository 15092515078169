
import { cn } from "@/lib/utils";
import { useEffect, useState } from "react";


const List = ({ item, className, index, activeItem, onClick, ...props }) => {
    return (
        <div
            className={cn(
                "relative flex h-full w-20 min-w-10 cursor-pointer group overflow-hidden rounded-md transition-all delay-0 duration-300 ease-in-out",
                {
                    "flex-grow": index === activeItem,
                },
                className,
            )}
            {...props}
        >
            <img
                src={item.image}
                alt={item.title}
                className={cn("h-full w-full object-cover", {
                    "blur-[2px]": index !== activeItem,
                })}
            />
            {index === activeItem && (
                <div className="absolute bottom-4 left-4 min-w-fit text-cyan-600 md:bottom-8 md:left-8" role="button" onClick={() => onClick(item)}>
                    <span className="items-start font-nunito italic justify-start text-xl sm:text-2xl md:text-6xl text-black-white group-hover:underline">
                        {item.title}
                    </span>
                </div>
            )}
        </div>
    );
};

export default function AccordionCarousel({ list = [], autoPlay = true, onClick, className }) {
    const [activeItem, setActiveItem] = useState(0);
    const [isHovering, setIsHovering] = useState(false);

    useEffect(() => {
        if (!autoPlay) {
            return;
        }

        const interval = setInterval(() => {
            if (!isHovering) {
                setActiveItem((prev) => (prev + 1) % list.length);
            }
        }, 5000);

        return () => clearInterval(interval);
    }, [autoPlay, list.length, isHovering]);

    return (
        <div className={cn("flex h-96 w-full gap-1", className)}>
            {list.map((item, index) => (
                <List
                    key={item.title}
                    item={item}
                    index={index}
                    activeItem={activeItem}
                    onMouseEnter={() => {
                        setActiveItem(index);
                        setIsHovering(true);
                    }}
                    onMouseLeave={() => {
                        setIsHovering(false);
                    }}
                    onClick={onClick}
                />
            ))}
        </div>
    );
}
