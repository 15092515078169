
import { VerticalCarousel } from '@/Components/extension/vertical-carousel';
import { Button } from '@/Components/ui/button';
import Marquee from '@/Components/ui/marquee';
import { navigate, viewImage } from '@/Hooks/Functions';
import { cn } from '@/lib/utils';
import Loader from '@/Modules/Loader';
import { useAiPromptResponse } from '@/Pages/Prompting/utils';
import video1 from '@assets/videos/rmp-5.mp4';
import { useCallback, useMemo } from 'react';
import { fetchShowcases } from './HeroChoosePredesign';
import { useQuery } from '@tanstack/react-query';


export const DefaultBlock = () => {
    const videos = [
        { src: video1 },
        { src: video1 },
        { src: video1 },
        { src: video1 },
    ]


    return <div id="highlight-pending-image" className="grid relative grid-cols-2 lg:max-w-[500px] md:max-w-[400px] items-stretch gap-8">
        {videos.map((video, index) => <div data-aos="fade-up" key={index} className=" col-span-1 z-[2] md:min-h-[200px] min-h-[100px] aspect-square border-2 aspect-square flex flex-col justify-center items-center border-2 bg-white p-4 rounded-2xl shadow-xl">
            <div className='relative aspect-square md:min-h-[200px] min-h-[100px]'>
                <video autoPlay loop muted src={video.src} className="absolute top-0 left-0 w-full h-full object-cover" />
            </div>
        </div>)}
    </div>
}

const ReviewCard = ({ showcase, onClick }) => {
    return (
        <figure
            className="relative h-full w-full isolate cursor-pointer group/item bg-transparent overflow-hidden rounded-2xl"
        >
            <Button onClick={() => onClick(showcase)} variant="theme" className="z-[3] absolute-center group-hover/item:visible invisible">Choose</Button>
            <div className='absolute w-full z-[2] left-0 top-0 h-full bg-primary/40 group-hover/item:visible invisible'></div>
            <div className="flex flex-row items-center">
                <img className="rounded-2xl" alt="" src={showcase.url_thumb} />
                <div className="flex flex-col">
                    <figcaption className="text-sm font-medium dark:text-white">
                        {showcase.showcasable?.name}
                    </figcaption>
                    {/* <p className="text-xs font-medium dark:text-white/40">{username}</p>   */}
                </div>
            </div>
            <blockquote className="mt-2 text-sm"> {showcase.showcasable?.name}</blockquote>
        </figure>
    );
};

export const DefaultBlock2 = () => {
    const { setSelected } = useAiPromptResponse();

    const { data, isLoading } = useQuery({
        queryKey: ['showcase', "all", "design"],
        queryFn: () => fetchShowcases("all", "design", 1, 32),
        refetchOnWindowFocus: false
    });

    const onShowcaseSelect = useCallback((selected) => {
        setSelected({
            url: selected.url_inline,
            id: selected.showcase_id,
            type: selected.showcase_type,
            svg: selected.is_svg
        });
        switch (selected.type) {
            case "ai-prompt-image":
                navigate(route('designer.via-design-phase', { via: 'prompts', uuid: selected.showcase_id }))
                break;
            case "template":
                navigate(route('designer.via-design-phase', { via: 'templates', uuid: selected.showcase_id }))
                break;
            case "clipart":
                navigate(route('designer.via-design-phase', { via: 'cliparts', uuid: selected.showcase_id }))
                break;
            default:
                break;
        }
    }, []);
    const list = useMemo(() => data?.data || [], [data]);
    const firstRow = useMemo(() => list?.slice(0, Math.floor(list.length / 2)) || [], [list]);
    const secondRow = useMemo(() => list?.slice(Math.floor(list.length / 2)) || [], [list]);

    return <div className="relative flex h-[700px] w-full flex-row items-center justify-center overflow-hidden shadow-none border-none">
        <Marquee reverse pauseOnHover vertical className="[--duration:200s]">
            {firstRow.map((review) => (
                <ReviewCard key={`block-1-${review.showcase_id}`} showcase={review} onClick={onShowcaseSelect} />
            ))}
        </Marquee>
        <Marquee pauseOnHover vertical className="[--duration:200s]">
            {secondRow.map((review) => (
                <ReviewCard key={`block-2-${review.showcase_id}`} showcase={review} onClick={onShowcaseSelect} />
            ))}
        </Marquee>
        {/* <div className="pointer-events-none absolute bg-transparent inset-x-0 top-0 h-1/3 bg-gradient-to-b from-white dark:from-background"></div> */}
        {/* <div className="pointer-events-none absolute inset-x-0 bottom-0 h-1/3 bg-gradient-to-t from-white dark:from-background"></div> */}
    </div>

}

export const ResultBlock = () => {

    const { response, setSelected } = useAiPromptResponse();

    const images = useMemo(() => response?.images || [], [response])

    const handleChoose = useCallback((image) => {
        setSelected({
            url: image.url,
            id: image.uuid,
            svg: false,
            type: 'ai-prompts'
        });
        navigate(route('designer.via-design-phase', { via: 'prompts', uuid: image.uuid, }))
    }, []);

    return <div id="highlight-pending-image" className="grid  grid-cols-2 lg:max-w-[500px] md:max-w-[400px] items-stretch gap-8">

        {Boolean(response && images.length === 0) && [1, 2, 3, 4].map(i => <Loader key={i} className=" col-span-1 rounded-2xl bg-white text-primary md:h-[200px] h-[100px] aspect-square" text="Generating image ..." />)}

        {Boolean(response && images.length > 0) && images.map((image) => <div data-aos="fade-up" key={image.id} className="col-span-1 bg-white md:min-h-[200px] min-h-[100px] aspect-square border-2 aspect-square flex flex-col justify-center items-center border-2 bg-white p-4 rounded-2xl shadow-xl">
            <div className='relative aspect-square md:min-h-[200px] min-h-[100px]'>
                <img src={image.url_inline} className="absolute top-0 left-0 w-full h-full object-cover" />
                <div className='flex w-full h-full absolute top-0 gap-2 left-0 bg-black/50 opacity-0 hover:opacity-100 transition-opacity duration-300 flex-col justify-center items-center'>
                    <Button onClick={() => viewImage(image?.url_inline)} className="rounded-full w-1/2" variant="theme-outline" size="xs">View</Button>
                    <Button onClick={() => handleChoose(image)} className="rounded-full w-1/2" variant="theme" size="xs">Choose</Button>
                </div>
            </div>
        </div>)}
    </div>
}
