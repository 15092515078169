import FancySwitch from "@/Components/extension/fancy-switch";
import { FlipWords } from "@/Components/FlipWords";
import InfoIcon from "@/Components/InfoIcon";
import InputLabel from "@/Components/InputLabel";
import { Button } from "@/Components/ui/button";
import { Card, CardContent } from "@/Components/ui/card";
import { Input } from "@/Components/ui/input";
import ShimmerButton from "@/Components/ui/shimmer-button";
import { Switch } from "@/Components/ui/switch";
import WordRotate from "@/Components/ui/word-rotate";
import { navigate, setLoader, ucwords } from "@/Hooks/Functions";
import { useBreakPoint } from "@/Hooks/Utilities";
import { useEcho } from "@/Contexts/echo-context";
import { ConfirmDialog } from "@/lib/mixins";
import { cn } from "@/lib/utils";
import { mockAiPrompting } from "@/Mocks/prompting";
import Empty from "@/Modules/Empty";
import FileGalleryPersonal from "@/Modules/FileGalleryPersonal";
import { FormTagsGroup } from "@/Modules/FormGroup";
import ArtStylesModal from "@/Pages/DesignerV2/components/ArtStylesModal";
import { AiAccountPopover } from "@/Pages/Prompting/components/AiAccountSelector";
import { useAiPromptForbidden, useAiPromptResponse } from "@/Pages/Prompting/utils";
import { Icon } from "@iconify/react";
import { usePage } from "@inertiajs/react";
import { useGlobalModals } from "@state/store";
import { useQuery } from "@tanstack/react-query";
import { useObjectState } from "@uidotdev/usehooks";
import { useMemoizedFn } from "ahooks";
import { useCallback, useMemo, useRef } from "react";
import { Popover as ReactPopover } from "react-tiny-popover";
import { toast } from "sonner";
import { useShallow } from "zustand/react/shallow";
import { usePromptBuilder, usePromptOptions } from "../store";
import { useHomeFlow } from "../utils";
import { DefaultBlock, DefaultBlock2, ResultBlock } from "./ResultBlock";
import { StepByStep } from "./ThreeSteps";

const PromptBuilder = () => {

    const [builder, setBuilder] = useObjectState({
        open: false,
        mode: 'options',
    });

    const { setGlobalModals, setOpenFileLibrary } = useGlobalModals(useShallow(state => ({
        setGlobalModals: state.setGlobalModals,
        setOpenFileLibrary: state.setMyFileLibrary
    })));
    const setArtStyleModal = useGlobalModals(state => state.setArtStyleModal);
    const iconRef = useRef();

    const { visibilityOptions, aspectRatioOptions, availableTags, artStyles } = usePromptOptions();

    const setData = usePromptBuilder(state => state.set);
    const resetData = usePromptBuilder(state => state.reset);
    const { account, cref, sref, prompt, precisions, artStyle, negative, negatives, tiles, plainBg, ar, visibility } = usePromptBuilder(useShallow(
        state => ({
            account: state.account,
            cref: state.cref,
            sref: state.sref,
            prompt: state.prompt,
            precisions: state.precisions,
            artStyle: state.artStyle,
            negative: state.negative,
            negatives: state.negatives,
            tiles: state.tiles,
            plainBg: state.plainBg,
            ar: state.ar,
            visibility: state.visibility
        })
    ))

    const addToPrecision = useCallback((item) => {
        setData("precisions", [...new Set([...precisions, item])])
    }, [precisions]);

    const onAddCref = useMemoizedFn(({ type, item }) => {
        setData('cref', item.url_inline);
        setGlobalModals({ myFileLibrary: false })
        setBuilder({ open: true });
    })

    const onAddSref = useMemoizedFn(({ type, item }) => {
        setData('sref', item.url_inline);
        setGlobalModals({ myFileLibrary: false })
        setBuilder({ open: true });
    })

    const onSetCref = useMemoizedFn(() => {
        setOpenFileLibrary(true, onAddCref);
        setBuilder({ open: false });
    })

    const onSetSref = useMemoizedFn(() => {
        setOpenFileLibrary(true, onAddSref);
        setBuilder({ open: false });
    })

    return <ReactPopover
        isOpen={builder.open}
        clickOutsideCapture={true}
        onClickOutside={() => setBuilder({ open: true })}
        positions={["bottom", "top", "right", "left"]}
        transform={{ top: 2 }}
        transformMode="relative"
        reposition={false}
        content={<Card className="min-h-[50px] isolate shadow-xl md:w-[600px] w-full !rounded-2xl">
            {builder.mode === 'options' ? <CardContent className="w-full min-h-[350px] md:divide-x divide-x-0 md:divide-y-0 divide-y overflow-y-auto flex md:flex-row flex-col md:flex-nowrap flex-wrap w-full justify-start items-stretch md:gap-4 gap-2">
                <div className="md:w-1/2 w-full gap-4 flex flex-col md:pr-0 pr-4 pl-4 py-4 ">
                    <FormTagsGroup maxTags={10} placeholder="+ Add more precisions" labelClass="text-xs font-semibold" className="w-full" label="Prompt Precision :" value={precisions} onChange={v => setData('precisions', v)} />
                    <div className="w-full gap-2 grid grid-cols-2">
                        {availableTags.map((item, index) => <Button key={index}
                            variant={item !== artStyle ? "outline" : "theme"}
                            onClick={() => addToPrecision(item)}
                            className="text-xs font-semibold px-3 py-2 h-4"><Icon icon="mdi:plus" />{item}</Button>)}
                    </div>
                    <div className="w-full flex gap-3 flex-col">
                        <div className="flex w-full flex-row flex-nowrap justify-between items-center">
                            <InputLabel className="text-xs font-semibold">Art Styles (Choose 1) :</InputLabel>
                            {artStyle && <Button variant="outline" className="text-xs text-cyan-600 font-semibold px-3 py-2 h-5" onClick={() => setData('artStyle', null)}>{artStyle} <Icon icon="mdi:close" /></Button>}
                        </div>
                        <div className="w-full gap-2 grid grid-cols-2">
                            {artStyles.map((item) => <Button key={item.id}
                                variant={item.name !== artStyle ? "outline" : "theme"}
                                onClick={() => setData('artStyle', item.name)}
                                className="text-xs font-normal px-2 truncate py-2 h-5"><Icon icon="mdi:plus" /> {item.name}</Button>)}
                            <Button
                                onClick={() => setArtStyleModal(true)}
                                className="text-xs font-semibold px-3 py-2 h-4">View More Styles</Button>
                        </div>
                    </div>
                </div>
                <div className="md:w-1/2 w-full gap-2 flex flex-col justify-center items-center gap-4 p-4">
                    <div className="w-full flex flex-row justify-between items-center gap-1">
                        <InputLabel className="text-xs font-semibold">Visibility</InputLabel>
                        <FancySwitch options={visibilityOptions} value={visibility} onChange={(v) => setData('visibility', v)} radioClassName="text-xs font-semibold px-3 h-4" />
                    </div>
                    <div className="w-full flex flex-col justify-start items-center gap-2">
                        <InputLabel className="text-xs font-semibold w-full text-left">Aspect Ratio</InputLabel>
                        <FancySwitch displayLimit={6} options={aspectRatioOptions} value={ar} onChange={(v) => setData('ar', v)} radioClassName="text-xs font-semibold px-3 h-4" />
                    </div>
                    <div className="w-full flex flex-row justify-between items-center gap-1">
                        <InputLabel className="text-xs font-semibold">Tiles <InfoIcon content="Ideal for repeating texture or background" /></InputLabel>
                        <Switch dense checked={tiles} onCheckedChange={v => setData('tiles', v)} />
                    </div>
                    <div className="w-full flex flex-row justify-between items-center gap-1">
                        <InputLabel className="text-xs font-semibold">Plain Background <InfoIcon content="This will instruct the AI to generate an image where the main subject is displayed on a simple, plain background, minimizing distractions." /></InputLabel>
                        <Switch dense checked={plainBg} onCheckedChange={v => setData('plainBg', v)} />
                    </div>
                    <div className="w-full flex flex-row justify-between items-center gap-1">
                        <InputLabel className="text-xs font-semibold">Negative <InfoIcon content="Negative prompting allows you to exclude or downplay certain elements from your image generation by specifying what you don’t want in the image. This helps the AI focus on the aspects you do want while minimizing or removing undesired features." /></InputLabel>
                        <Switch dense checked={negative} onCheckedChange={v => setData('negative', v)} />
                    </div>
                    {negative && <div className="w-full flex flex-row justify-between items-center gap-1">
                        <FormTagsGroup maxTags={10} placeholder="+ Enter subject to excluded e.g. shadow" labelClass="text-xs font-semibold" className="w-full" label="Subject to excludes :" value={negatives} onChange={v => setData('negatives', v)} />
                    </div>}
                    <div className="fw-full flex flex-grow h-full flex-row justify-center items-center gap-2">
                        <Button variant="outline" onClick={resetData} className="px-8 rounded-full">Reset</Button>
                        <Button onClick={() => setBuilder({ open: false })} variant="theme" className="px-8 rounded-full">Done</Button>
                    </div>
                </div>
            </CardContent> :
                <CardContent className="w-full min-h-[350px] md:divide-x divide-x-0 md:divide-y-0 divide-y overflow-y-auto w-full grid md:grid-cols-2 grid-cols-1 items-stretch md:gap-4 gap-2">
                    <div className="flex flex-col gap-4 justify-start items-center col-span-1 p-2">
                        <div className="w-full flex justify-between items-center flex-row flex-nowrap">
                            <InputLabel className="flex-1 text-xs font-semibold">Character Reference</InputLabel>
                            <Button size="xs" variant="outline" className="px-4" onClick={onSetCref}>Upload/File Library</Button>
                        </div>
                        {cref ? <div className="w-full relative flex justify-center items-center p-2 flex-row flex-nowrap">
                            <img src={cref} className="max-h-[250px] shadow-xl rounded-2xl" />
                            <Button variant="destructive-outline" onClick={() => setData('cref', null)} size="xs" className="px-4 absolute top-2 left-1 rounded-full">Remove character reference</Button>
                        </div> : <Empty icon="carbon:no-image" className="h-full flex-grow" text="No Character Reference" />}
                    </div>
                    <div className="flex flex-col gap-4 justify-start items-center col-span-1 p-2">
                        <div className="w-full flex justify-between items-center flex-row flex-nowrap">
                            <InputLabel className="flex-1 text-xs font-semibold">Style Reference</InputLabel>
                            <Button size="xs" variant="outline" className="px-4" onClick={onSetSref}>Upload/File Library</Button>
                        </div>
                        {sref ? <div className="w-full relative flex justify-center p-2 items-center flex-row flex-nowrap">
                            <img src={sref} className="max-h-[250px] shadow-xl rounded-2xl" />
                            <Button variant="destructive-outline" onClick={() => setData('sref', null)} size="xs" className="px-4 absolute top-2 left-1 rounded-full">Remove style reference</Button>
                        </div> : <Empty icon="carbon:no-image" className="h-full flex-grow" text="No Style Reference" />}
                    </div>
                </CardContent>}
        </Card >
        }
    >
        <div data-aos="fade-up" className="w-full gap-2 max-w-[600px] flex items-center">
            <Icon ref={iconRef} data-tooltip-id="rmp-tooltip" data-tooltip-variant="dark" data-tooltip-content="Add character and style reference" onClick={() => setBuilder(s => ({ open: s.open && s.mode === 'files' ? false : true, mode: 'files' }))} icon={builder.open && builder.mode === 'files' ? "mdi:close" : "fluent:image-add-24-regular"} className="hover:rotate-180 absolute text-primary hover:text-cyan-600 cursor-pointer top-[calc(50%-12px)] text-2xl left-3 " />
            <Input placeholder="Enter your prompt or subject here..." className={cn("w-full px-[40px] !min-h-[50px] rounded-xl", !prompt ? "text-center" : 'text-left')} value={prompt} onChange={e => setData('prompt', e.target.value)} />
            <Icon data-tooltip-id="rmp-tooltip" data-tooltip-variant="dark" data-tooltip-content="See more options" onClick={() => setBuilder(s => ({ open: s.open && s.mode === 'options' ? false : true, mode: 'options' }))} icon={builder.open && builder.mode === 'options' ? "mdi:close" : "el:magic"} className="absolute text-primary hover:rotate-180 hover:text-cyan-600 cursor-pointer top-[calc(50%-12px)] text-2xl right-3 " />
        </div>
    </ReactPopover >
}


export default function HeroPrompt({ className = "" }) {
    const { auth } = usePage().props;
    const { setStep } = useHomeFlow();

    const { subscribeToChannel, unsubscribeFromChannel } = useEcho();
    const set = usePromptBuilder(state => state.set);
    const resetForm = usePromptBuilder(state => state.resetForm);
    const payload = usePromptBuilder(state => state.payload);
    // const generatedPrompt = usePromptBuilder(state => state.generatedPrompt);
    const errors = usePromptBuilder(state => state.errors);
    const isValid = useMemo(() => errors.length === 0, [errors]);
    const { response, setResponse } = useAiPromptResponse();
    const { getForbiddenWords } = useAiPromptForbidden();
    const bp = useBreakPoint();

    const setArtStyleModal = useGlobalModals(state => state.setArtStyleModal);

    const pePage = useMemo(() => bp === 'xs' ? 3 : 5, [bp]);

    const samplePrompts = [
        "e.g. Girl dressed like a cat, in the style of Kawaii Anime, Kanahei inspire",
        "e.g. Rabbit holding a balloon, cute happy kawaii style, colorful",
        "e.g. Octopus Reading a Book, tshirt design graphic, in the style of kawaii",
        "e.g. Cute cat wearing sunglasses and a Hawaiian shirt, lounging under a palm tree"
    ];
    const words = ["T-Shirt", "Sweater", "Hoodies", "Mugs", "Caps", "Frames"];

    const { data: faturedStyles } = useQuery({
        queryKey: ['home-featured-styles', pePage],
        queryFn: () => window.axios.get(route('artstyles.paginate', { featured: 1, per_page: pePage, page: 1, active: 1 })).then(({ data }) => data.data),
        refetchOnWindowFocus: false
    })

    const onGenerate = useCallback(() => {
        if (!auth?.user) {
            ConfirmDialog.fire({
                title: 'Authentication Required!',
                html: `Please login or create an account to continue.`,
                icon: "error",
                showDenyButton: true,
                confirmButtonText: 'Log In',
                denyButtonText: 'Register'
            }).then(({ isConfirmed, isDenied }) => {
                if (isConfirmed) navigate(route('login', { redirect_uri: window.location.origin }));
                else if (isDenied) navigate(route('register', { redirect_uri: window.location.origin }));
            })
            return;
        }

        if (errors?.length > 0) {
            ConfirmDialog.fire({
                title: 'Invalid Prompt',
                html: errors.join('<br/>'),
                icon: 'error',
            });
            return;
        }

        const forbiddens = getForbiddenWords(payload?.prompt);
        if (!!forbiddens) {
            ConfirmDialog.fire({
                title: 'Invalid Or Contain Inappropriate Prompt',
                html: `
                **ALLOWED**<br/>
                - Any image up to PG-13 rating involving fiction, fantasy, mythology.<br/>
                - Real images that may be seen as respectful or light-hearted parodies, satire, caricatures<br/>
                - Imaginary or exaggerated real-life scenarios, including absurd or humorous situations.<br/><br/>

                **NOT ALLOWED**<br/>
                - Disrespectful, harmful, misleading public figures/events portrayals or potential to mislead.<br/>
                - Hate speech, explicit or real-world violence.<br/>
                - Nudity or unconsented overtly sexualized public figures.<br/>
                - Imagery that might be considered culturally insensitive<br/><br/>
                Your prompt contains forbidden ${forbiddens.types} words. Please remove or rephrase them and try again.<br/> [${forbiddens.words}]`,
                icon: null,
                cancelButtonText: "More Info"
            }).then(({ isDismissed, dismiss }) => {
                (isDismissed && dismiss === 'cancel') && navigate(route('forbidden.list'))
            })
            return;
        }

        setLoader(true, "Sending prompt...")
        window.axios.post(route("prompt.send-prompt"), payload)
            .then(({ data }) => {
                setResponse(data);
                setStep('create-design');
                resetForm();
                subscribeToChannel(`ai-image.${data?.uuid}`, '.ai-image-created-event', ({ data }) => {
                    if (data?.status === 'completed') {
                        toast.success('Image created successfully')
                        setResponse(data);
                        unsubscribeFromChannel(`ai-image.${data?.uuid}`)
                    }
                })
            })
            .catch(error => {
                toast.error(error.response?.data?.message || "An error occurred");
            })
            .finally(() => setLoader(false));

    }, [errors, payload, setResponse, resetForm, getForbiddenWords, auth?.user]);

    return <>
        <Card className="w-full bg-transparent isolate rounded-2xl relative border-none">
            {/* <div className="absolute h-full  w-full bg-[radial-gradient(#d3d3d3_1px,transparent_2px)] [background-size:16px_16px] [mask-image:radial-gradient(ellipse_50%_50%_at_50%_50%,#000_70%,transparent_100%)]"></div> */}

            <CardContent className={cn("w-full z-20 grid min-h-[calc(100vh-230px)] items-stretch md:grid-cols-2 grid-cols-1 gap-2", className)}>
                <div className="col-span-1 flex flex-col justify-center gap-4 h-full items-center relative p-4">
                    <div className="mx-auto flex flex-col gap-4 justify-center items-center w-full max-w-[700px]">
                        <div className="md:order-1 order-2  w-full justify-center flex flex-row items-center">
                            <h1 data-aos="fade-up" className="md:text-3xl text-md whitespace-nowrap font-semibold">Create Design For </h1> <FlipWords duration={1000} className="text-cyan-600 md:w-[150px] inline-flex w-fit text-left md:text-3xl text-md font-semibold" words={words} />
                        </div>

                        <p data-aos="fade-up" className="md:order-2 order-3 font-semibold">What Will You Dream Up Today?</p>

                        <div className="md:order-3 order-4 flex flex-col rounded-4xl relative justify-center items-center w-full gap-2 mt-4">
                            {/* <p className="italic text-center text-xs p-4">[DEV] : {generatedPrompt}</p> */}
                            <div className="w-full flex flex-row justify-center gap-1 items-center">
                                <WordRotate className="text-xs text-gray-400" words={samplePrompts} />
                            </div>
                            <div className="w-full gap-2 max-w-[600px] flex items-center">
                                <AiAccountPopover onAccountChange={v => set('account', v)} />
                                <PromptBuilder />
                            </div>
                            <div data-aos="fade-up" className="w-full max-w-[600px] gap-2 flex-nowrap flex justify-end items-center">
                                {faturedStyles?.map(item => <Button key={item.id} variant={payload?.artStyle === item.name ? "theme" : "outline"} size="xs" className="rounded-md" onClick={() => set('artStyle', item.name)}>{ucwords(item.description)}</Button>)}
                                <Button variant="outline" size="xs" className="rounded-md" onClick={() => setArtStyleModal(true)}>More ...</Button>
                            </div>
                        </div>
                        {/* {JSON.stringify(payload, null, 2)} */}
                        <ShimmerButton onClick={onGenerate} background={isValid ? "#0891b2" : "#fff"} borderRadius="20px" className={cn("shadow-2xl md:order-4 order-5 min-w-[250px] md:w-fit w-full mt-4")} shimmerColor={isValid ? "#fff" : "#0891b2"}>
                            <span className={cn("whitespace-nowrap text-center text-sm font-medium leading-none tracking-tight  dark:from-white dark:to-slate-900/10 lg:text-lg", isValid ? "text-white" : "text-cyan-600")}>
                                Generate
                            </span>
                        </ShimmerButton>
                        {/* <Button disabled={!isValid} variant={isValid ? "theme" : "theme-outline"}className="md:order-4 min-w-[250px] font-semibold order-5 md:w-fit w-full mt-4 rounded-full" size="xl">Generate</Button> */}

                        <Button data-aos="fade-up" onClick={() => setStep('choose-predesign')} variant="link" className="md:order-5 order-6 hover:text-cyan-600 w-full underline  mt-4 lg:mb-4 mb-2">or Choose from Ready-Made Designs</Button>

                        <StepByStep activeStep={1.5} className="md:order-6 order-1 w-full lg:max-w-[500px] mx-auto" />
                    </div>
                </div>

                <div className="col-span-1 flex flex-col z-20 justify-center h-full items-center p-4">
                    {response ? <ResultBlock /> : <DefaultBlock2 />}
                </div>
            </CardContent>
        </Card>

        <FileGalleryPersonal />
        <ArtStylesModal />
    </>
}
