import React from 'react';
import { toast } from 'sonner';
import { create } from 'zustand';

const useEchoStore = create((set, get) => ({
    channels: {},

    subscribeToChannel: (channelName, eventName, callback) => {
        if (typeof callback === undefined) {
            toast.error('Callback is undefined');
            return;
        }
        if (typeof eventName === undefined) {
            toast.error('Event Name is undefined');
            return;
        }
        if (typeof channelName === undefined) {
            toast.error('Channel Name is undefined');
            return;
        }

        const channel = window.Echo.channel(channelName);
        console.log(`Subscribed to: ${channelName}`);

        channel.listen(eventName, callback);
        console.log(`Listening for event: ${eventName} on channel: ${channelName}`);

        set((state) => ({
            channels: { ...state.channels, [channelName]: channel }
        }));
    },

    unsubscribeFromChannel: (channelName) => {
        const channels = get().channels;

        if (channels[channelName]) {
            window.Echo.leaveChannel(channelName);
            set((state) => {
                const { [channelName]: _, ...rest } = state.channels;
                return { channels: rest };
            });
            console.log(`Unsubscribed from: ${channelName}`);
        }
    },

    cleanup: () => {
        const channels = get().channels;
        Object.keys(channels).forEach((channelName) => {
            window.Echo.leaveChannel(channelName);
        });
        set({ channels: {} });
    }
}));

// Hook to use in components
export const useEcho = () => {
    const { subscribeToChannel, unsubscribeFromChannel } = useEchoStore();

    // Handle cleanup on component unmount
    React.useEffect(() => {
        return () => {
            useEchoStore.getState().cleanup();
        };
    }, []);

    return { subscribeToChannel, unsubscribeFromChannel };
};
