import { proxy } from 'valtio'
import { create } from 'zustand';

const state = proxy({
    comingSoon: false,
    processing: false,
    processing_text: "Processing...Please wait...",
    intro: true,
    colors: ['#ccc', '#EFBD4E', '#80C670', '#726DE8', '#EF674E', '#353934'],
    decals: ['react', 'three2', 'pmndrs'],
    color: '#80C670',
    decal: 'three2',
})

const orderState = proxy({
    list: ["draft", "pending", "failed", "canceled", "inprocess", "onhold", "partial", "fulfilled"],
    draft: { color: "#1f2937", description: "At this stage, the order has been created but has not yet been submitted for fulfillment. It can be freely edited, changed, or deleted at this stage. The order will not charged or fulfilled until it has been confirmed." },
    pending: { color: "#d97706", description: "The order has been submitted for fulfillment and it will be charged in this stage, but is not yet accepted for fulfillment. The order can be cancelled at this stage so long as it has not been charged yet." },
    failed: { color: "#dc2626", description: "Order was submitted for fulfillment but it had some errors preventing it from being fulfilled (problem with the address, missing printfiles, failed to charge the wallet, etc.)." },
    canceled: { color: "#ea580c", description: "The order has been canceled and can no longer be processed. If the order was already charged then the cost of the order will be returned to your store." },
    inprocess: { color: "#0891b2", description: "The order is being fulfilled and can no longer be canceled or modified. Contact customer support if there are any issues with the order at this point." },
    onhold: { color: "#db2777", description: "The order has encountered a problem during the fulfillment that needs to be resolved together with Printful customer service before fulfillment can continue." },
    partial: { color: "#65a30d", description: "The order is partially fulfilled (some items are shipped already, the rest will follow)." },
    fulfilled: { color: "#16a34a", description: "All items have been shipped successfully." },
    color: (val) => orderState?.[val.toLowerCase()] ? orderState[val.toLowerCase()].color : '#000000',
})

const paymentState = proxy({
    "pending": { color: "#d97706", description: "The payment is still pending" },
    "partial": { color: "#65a30d", description: "The payment have been collected partially" },
    "completed": { color: "#16a34a", description: "All payments have been collected successfully." },
    color: (val) => paymentState?.[val.toLowerCase()] ? paymentState[val.toLowerCase()].color : '#000000',
})

const commonColors = [
    { name: "White", hex: "#FFFFFF" },
    { name: "Black", hex: "#000000" },
    { name: "Red", hex: "#FF0000" },
    { name: "Green", hex: "#00FF00" },
    { name: "Blue", hex: "#0000FF" },
    { name: "Yellow", hex: "#FFFF00" },
    { name: "Cyan", hex: "#0891B2" },
    { name: "Magenta", hex: "#FF00FF" },
    { name: "Silver", hex: "#C0C0C0" },
    { name: "Gray", hex: "#808080" },
    { name: "Maroon", hex: "#800000" },
    { name: "Olive", hex: "#808000" }
];

const useGlobalModals = create((set) => ({
    notificationModal: false,
    shoppingCart: false,
    myFileLibrary: false,
    myFileLibraryCallback: undefined,
    systemFileLibrary: false,
    systemFileLibraryCallback: undefined,
    artStyleModal: false,
    aiModelModal: false,
    styleModal: false,
    setNotificationModal: (val) => set({ notificationModal: val }),
    setStyleModelModal: (val) => set({ styleModal: val }),
    setAiModelModal: (val) => set({ aiModelModal: val }),
    setArtStyleModal: (val) => set({ artStyleModal: val }),
    setMyFileLibrary: (val, cb) => set({ myFileLibrary: val, myFileLibraryCallback: cb }),
    setSystemFileLibrary: (val, cb) => set({ systemFileLibrary: val, systemFileLibraryCallback: cb }),
    setShoppingCart: (val) => set({ shoppingCart: val }),
    setGlobalModals: (val) => set(val)
}))

export { state, orderState, paymentState, commonColors, useGlobalModals }
