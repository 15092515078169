
import ApplicationLogo from "@/Components/ApplicationLogo";
import { Button } from "@/Components/ui/button";
import { Card, CardContent } from "@/Components/ui/card";
import { useProductCategories } from "@/Hooks/CategoriesHook";
import { cn } from "@/lib/utils";
import invert from "invert-color";
import {
    Carousel,
    CarouselContent,
    CarouselItem,
    CarouselNext,
    CarouselPrevious,
} from "@/Components/ui/carousel"
import { useEffect, useMemo, useState } from "react";
import { Img } from "react-image";
import { Link } from "@inertiajs/react";
import { useBreakPoint } from "@/Hooks/Utilities";

export default function CategoriesPart() {
    const bgColors = ['#d8d9da', '#61677a', '#393e46', '#a6d5ea', '#1687a7', '#276678', '#d8d9da', '#61677a', '#393e46', '#a6d5ea', '#1687a7', '#276678', '#d8d9da', '#61677a', '#393e46', '#a6d5ea', '#1687a7', '#276678', '#d8d9da', '#61677a', '#393e46', '#a6d5ea', '#1687a7', '#276678', '#d8d9da', '#61677a', '#393e46', '#a6d5ea', '#1687a7', '#276678'];

    const bp = useBreakPoint();

    const breakPoints = {
        'xs': 2,
        'sm': 2,
        'md': 4,
        'lg': 4,
        'xl': 6,
        '2xl': 6,
        '3xl': 6,
    }
    const gridBreakPoints = {
        'xs': 1,
        'sm': 1,
        'md': 2,
        'lg': 2,
        'xl': 3,
        '2xl': 3,
        '3xl': 3,
    }

    const [perPage, setPerPage] = useState(breakPoints['xl']);
    const { categories } = useProductCategories();

    const groups = useMemo(() => {
        if (!categories) return [];
        const result = [];
        for (let i = 0; i < categories.length; i += perPage) {
            result.push(categories.slice(i, i + perPage));
        }
        return result;
    }, [categories, perPage]);

    useEffect(() => {
        setPerPage(breakPoints[bp]);
    }, [bp]);

    return <div className="mt-8 w-full flex shadow-xl shadow-spread-2 flex-col items-center rounded-2xl bg-white min-h-[70vh]">
        <div className="max-w-7xl w-full flex flex-col gap-x-4 py-8 gap-y-6">

            <div data-aos="fade-up" className="flex flex-col items-center w-full h-[180px] gap-2 justify-center">
                <h1 className="md:text-5xl text-primary text-3xl font-semibold">Categories</h1>
                <p className="md:text-3xl text-primary text-md font-semibold">Check Out Our Products</p>
            </div>
            <div className="w-full flex flex-col gap-4">
                <Carousel
                    opts={{ align: "center", }}
                    className="w-full lg:px-0 px-4"
                >
                    <CarouselContent>
                        {groups?.map((group, index) => (
                            <CarouselItem key={index} >
                                <div className="w-full grid gap-4" style={{ "gridTemplateColumns": `repeat(${gridBreakPoints[bp]}, minmax(0, 1fr))` }}>
                                    {group?.map((category, index) => <Card key={category.slug} data-aos="fade-up" className="col-span-1 overflow-hidden shadow-md shadow-spread rounded-2xl " style={{ backgroundColor: bgColors[index] }}>
                                        <CardContent className="w-full h-full  flex flex-row items-center justify-center min-h-[200px]" style={{ color: invert(bgColors[index], true) }}>
                                            <div className="w-1/2 flex h-full flex-grow justify-between items-start flex-col p-4">
                                                <h1 className="text-xl mt-6 md:text-3xl text-xl font-bold">{category.name}</h1>
                                                <Link href={route('home.predesign-products', { category: category.slug })}>
                                                    <Button className="px-6">Shop Now</Button>
                                                </Link>
                                            </div>
                                            <div className="w-1/2 flex flex-col justify-center h-full items-center">
                                                <Img src={category.image_url} className="h-full object-cover" />
                                            </div>
                                        </CardContent>
                                    </Card>)}
                                </div>
                            </CarouselItem>
                        ))}
                    </CarouselContent>
                    {groups?.length > 1 && <>
                        <CarouselPrevious data-aos="fade-up" iconClass="size-4" />
                        <CarouselNext data-aos="fade-up" iconClass="size-4" />
                    </>}
                </Carousel>
            </div>
        </div>
    </div>
}
