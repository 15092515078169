import { useEffect, useState } from "react";

export const generateRandomString = (length) => {
    if (length < 1) {
        throw new Error("Length must be at least 1");
    }

    const letters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const alphanumeric = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";

    // Ensure the first character is a letter
    let randomString = letters[Math.floor(Math.random() * letters.length)];

    // Generate the rest of the string
    for (let i = 1; i < length; i++) {
        randomString += alphanumeric[Math.floor(Math.random() * alphanumeric.length)];
    }
    return randomString;
}
/**
 * Custom hook to convert vh to pixels and handle resize
 * @param {number} vh - Viewport height value (e.g., 80 for 80vh)
 * @returns {number} - Pixel value corresponding to the vh
 */
export const useVhToPixels = (vh) => {
    const [pixels, setPixels] = useState(0);
    useEffect(() => {
        const calculatePixels = () => {
            const viewportHeight = window.innerHeight;
            const pixels = (vh / 100) * viewportHeight;
            setPixels(Math.round(pixels));
        };
        // Initial calculation
        calculatePixels();
        // Recalculate on window resize
        window.addEventListener('resize', calculatePixels);
        return () => window.removeEventListener('resize', calculatePixels);
    }, [vh]);
    return pixels;
};

/**
 * Adjusts template dimensions based on a target height while maintaining aspect ratio
 * @param {Object} template - The original template object
 * @param {number} targetHeight - The desired height for the adjusted template
 * @returns {Object} - New template object with adjusted dimensions
 */
export const adjustTemplateDimensions = (template, targetHeight) => {
    if (!template || !targetHeight) return null;
    // Calculate the scale factor based on target product height
    const scaleFactor = targetHeight / template.product_height;

    // Create a new template object with adjusted dimensions
    const adjustedTemplate = {
        ...template,
        // Scale all dimensions by the same factor
        height: Math.round(template.height * scaleFactor),
        width: Math.round(template.width * scaleFactor),
        top: Math.round(template.top * scaleFactor),
        left: Math.round(template.left * scaleFactor),

        // Set product dimensions
        product_height: targetHeight,
        product_width: Math.round(template.product_width * scaleFactor)
    };

    return adjustedTemplate;
};

/**
 * Calculates the multiplier for Fabric.js canvas based on adjusted template dimensions and target DPI
 * @param {Object} adjustedTemplate - The template object after adjustment
 * @param {number} targetDPI - The desired DPI for the final output
 * @returns {number} - The calculated multiplier for Fabric.js canvas
 */
export const calculateFullPageMultiplier = (adjustedTemplate, targetDPI = 300) => {
    if (!adjustedTemplate || !targetDPI) return null;
    // Convert inches to pixels at the target DPI
    const targetHeightInPixels = adjustedTemplate.product_height * targetDPI;
    // Calculate the multiplier
    const multiplier = targetHeightInPixels / adjustedTemplate.height;
    // Round to 6 decimal places for precision
    return Math.round(multiplier * 1000000) / 1000000;
};

/**
 * Calculates the multiplier for Fabric.js canvas based on adjusted template dimensions and target DPI,
 * focusing only on the print area
 * @param {Object} adjustedTemplate - The template object after adjustment
 * @param {number} targetDPI - The desired DPI for the final output
 * @returns {number} - The calculated multiplier for Fabric.js canvas print area
 */
export const calculatePrintareaMultiplier = (adjustedTemplate, targetDPI = 300) => {
    if (!adjustedTemplate || !targetDPI) return null;

    // Convert template dimensions from pixels to inches
    const templateWidthInInches = adjustedTemplate.width / targetDPI;
    const templateHeightInInches = adjustedTemplate.height / targetDPI;

    // Calculate the required multiplier for both dimensions
    const widthMultiplier = (templateWidthInInches * targetDPI) / adjustedTemplate.width;
    const heightMultiplier = (templateHeightInInches * targetDPI) / adjustedTemplate.height;

    // Use the smaller multiplier to ensure the entire print area fits while maintaining aspect ratio
    const multiplier = Math.min(widthMultiplier, heightMultiplier);

    // Round to 6 decimal places for precision
    return Math.round(multiplier * 1000000) / 1000000;
};


export const useMergeImages = (backgroundImage, overlayImage, template) => {
    const [mergedBase64, setMergedBase64] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        const mergeImages = async () => {
            if (!backgroundImage || !overlayImage || !template) {
                return;
            }

            try {
                // Create canvas
                const canvas = document.createElement('canvas');
                canvas.width = template.product_width;
                canvas.height = template.product_height;
                const ctx = canvas.getContext('2d');

                // Load background image
                const bgImg = new Image();
                bgImg.crossOrigin = 'anonymous';

                await new Promise((resolve, reject) => {
                    bgImg.onload = resolve;
                    bgImg.onerror = reject;
                    bgImg.src = backgroundImage;
                });

                // Draw background
                ctx.drawImage(bgImg, 0, 0, template.product_width, template.product_height);

                // Load overlay image
                const overlayImg = new Image();
                overlayImg.crossOrigin = 'anonymous';

                await new Promise((resolve, reject) => {
                    overlayImg.onload = resolve;
                    overlayImg.onerror = reject;
                    overlayImg.src = overlayImage;
                });

                // Draw overlay
                ctx.drawImage(
                    overlayImg,
                    template.left,
                    template.top,
                    template.width,
                    template.height
                );

                // Convert to base64
                const base64 = canvas.toDataURL('image/png');
                setMergedBase64(base64);
                setError(null);

            } catch (err) {
                setError(err);
                console.error('Error merging images:', err);
            }
        };

        mergeImages();
    }, [backgroundImage, overlayImage, template]);

    return { image: mergedBase64, error };
};

export const useBreakPoint = () => {
    const [breakPoint, setBreakPoint] = useState('xs');

    useEffect(() => {
        const breakpoints = {
            '2xl': '(min-width: 1536px)',
            'xl': '(min-width: 1280px)',
            'lg': '(min-width: 1024px)',
            'md': '(min-width: 768px)',
            'sm': '(min-width: 640px)',
            'xs': '(min-width: 0px)'
        };

        function getActiveBreakpoint() {
            for (const [breakpoint, query] of Object.entries(breakpoints)) {
                if (window.matchMedia(query).matches) {
                    return breakpoint;
                }
            }
            return 'xs';
        }

        // Debounce function
        function debounce(func, wait) {
            let timeout;
            return function executedFunction(...args) {
                const later = () => {
                    clearTimeout(timeout);
                    func(...args);
                };
                clearTimeout(timeout);
                timeout = setTimeout(later, wait);
            };
        }

        const handleResize = debounce(() => {
            setBreakPoint(getActiveBreakpoint());
        }, 100);

        // Initial check
        handleResize();

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return breakPoint;
};

const MOBILE_BREAKPOINT = 768

export function useIsMobile() {
    const [isMobile, setIsMobile] = useState(undefined)

    useEffect(() => {
        const mql = window.matchMedia(`(max-width: ${MOBILE_BREAKPOINT - 1}px)`)
        const onChange = () => {
            setIsMobile(window.innerWidth < MOBILE_BREAKPOINT)
        }
        mql.addEventListener("change", onChange)
        setIsMobile(window.innerWidth < MOBILE_BREAKPOINT)
        return () => mql.removeEventListener("change", onChange);
    }, [])

    return !!isMobile
}


export const useOutsideClick = (ref, callback) => {
    useEffect(() => {
        const listener = (event) => {
            if (!ref.current || ref.current.contains(event.target)) {
                return;
            }
            callback(event);
        };
        document.addEventListener("mousedown", listener);
        document.addEventListener("touchstart", listener);
        return () => {
            document.removeEventListener("mousedown", listener);
            document.removeEventListener("touchstart", listener);
        };
    }, [ref, callback]);
};


export const calculateBase64Size = (base64String) => {
    // Remove the base64 encoding prefix (e.g., 'data:image/jpeg;base64,')
    const base64Data = base64String.replace(/^data:image\/[a-zA-Z]+;base64,/, '');

    // Calculate the size of the base64 string in bytes
    const base64Length = base64Data.length;

    const sizeInBytes = 4 * Math.ceil((base64Length / 3)) * 0.5624896334383812;

    // Base64 encoding uses 4 characters to represent 3 bytes
    // const sizeInBytes = (base64Length * 3) / 4;

    // Convert the size to megabytes (MB) and round to two decimal places
    return (sizeInBytes / (1024 * 1024)).toFixed(2);  // Size in MB
}
