import { useBreakPoint } from "@/Hooks/Utilities";
import { cn } from "@/lib/utils";
import logo_full from "@public/logos/logo-full-color.png";
import logo_full_white from "@public/logos/logo-full-white.png";
import logo_square from "@public/logos/logo-square-color.png";
import logo_square_white from "@public/logos/logo-square-white.png";

export default function ApplicationLogo({
    white = false,
    square = false,
    className = "",
    responsive = true,
    ...rest
}) {

    const bp = useBreakPoint();

    if (responsive) {
        if (['xs', 'sm'].includes(bp)) {
            square = true;
        }
    }

    return (
        <img className={cn("max-w-[220px]", className)} {...rest} alt="..." src={
            !square ? white ? logo_full_white : logo_full
                : white ? logo_square_white : logo_square
        } />
    );
}
