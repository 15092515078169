"use client";
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuTrigger
} from "@/Components/ui/dropdown-menu";
import { navigate } from "@/Hooks/Functions";
import { useBreakPoint } from "@/Hooks/Utilities";
import UserPopover from "@/Layouts/partials/UserPopover";
import { cn } from "@/lib/utils";
import NotificationModal from "@/Modules/NotificationModal";
import ShoppingCart from "@/Modules/ShoppingCart";
import { Icon } from "@iconify/react";
import { Link, usePage } from "@inertiajs/react";
import {
    AnimatePresence,
    motion,
    useMotionValueEvent,
    useScroll,
} from "framer-motion";
import { useEffect, useState } from "react";

export const FloatingNav = ({ navItems, className, offset = 0, children }) => {
    const { scrollYProgress } = useScroll();
    const { auth } = usePage().props;

    const bp = useBreakPoint();
    const [visible, setVisible] = useState(false);
    const [isScrollable, setIsScrollable] = useState(false);

    useEffect(() => {
        const checkScrollable = () => {
            setIsScrollable(document.documentElement.scrollHeight > window.innerHeight);
        };

        checkScrollable();
        window.addEventListener('resize', checkScrollable);

        return () => window.removeEventListener('resize', checkScrollable);
    }, []);

    useMotionValueEvent(scrollYProgress, "change", (current) => {
        // Check if current is not undefined and is a number
        if (typeof current === "number" && isScrollable) {
            if (scrollYProgress.get() >= offset) {
                setVisible(true);
            } else {
                setVisible(false);
            }
        }
    });

    if (!isScrollable) return null;

    return (
        <AnimatePresence mode="wait">
            <motion.div
                initial={{ opacity: 1, y: -100 }}
                animate={{ y: visible ? 0 : -100, opacity: visible ? 1 : 0 }}
                transition={{ duration: 0.2 }}
                className={cn(
                    "flex max-w-fit fixed top-2 isolate inset-x-0 mx-auto border border-transparent dark:border-white/[0.2] rounded-full dark:bg-black bg-primary shadow-[0px_2px_3px_-1px_rgba(0,0,0,0.1),0px_1px_0px_0px_rgba(25,28,33,0.02),0px_0px_0px_1px_rgba(25,28,33,0.08)] z-[5000] px-8 py-2  items-center justify-center space-x-3",
                    className
                )}>
                {!['xs', 'sm'].includes(bp) ? navItems.map((navItem, idx) => (
                    <Link
                        key={`link=${idx}`}
                        href={navItem.route}
                        className={cn(
                            "md:relative hidden dark:text-white items-center flex space-x-1 text-white dark:hover:text-cyan-300 hover:text-cyan-600"
                        )}>
                        <Icon icon={navItem.icon} className="block sm:hidden"></Icon>
                        <span className="hidden sm:block text-sm">{navItem.label}</span>
                    </Link>
                )) : <DropdownMenu >
                    <DropdownMenuTrigger className="mr-auto">
                        <Icon icon="stash:burger-classic" className="text-2xl text-white hover:text-cyan-600" />
                    </DropdownMenuTrigger>
                    <DropdownMenuContent sideOffset={18} className="z-[99]">
                        {navItems.map((navItem, idx) => (
                            <DropdownMenuItem key={`link=${idx}`} onClick={() => navigate(navItem.route)}>{navItem.label}</DropdownMenuItem>
                        ))}
                    </DropdownMenuContent>
                </DropdownMenu>}

                {children}

                {auth?.user && <ShoppingCart iconClass="text-white" />}
                {auth?.user && <NotificationModal iconClass="text-white" />}
                {auth?.user && <UserPopover iconClass="text-white" />}

            </motion.div>
        </AnimatePresence>
    );
};
