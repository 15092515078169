import ApplicationLogo from "@/Components/ApplicationLogo";
import { Card, CardContent, CardHeader } from "@/Components/ui/card";
import { Separator } from "@/Components/ui/separator";
import HomeLayoutV2 from "@/Layouts/HomeLayoutV2";
import HeaaderV2 from "@/Layouts/partials/HeaaderV2";
import { Head, Link } from "@inertiajs/react";
import { useEffect } from "react";
import { useAiPromptResponse } from "../Prompting/utils";
import BestSellers from "./components/BestSellers";
import CategoriesPart from "./components/CategoriesPart";
import CodeFinder from "./components/CodeFinder";
import CollectionPart from "./components/CollectionPart";
import HeroChoosePredesign from "./components/HeroChoosePredesign";
import HeroPrompt from "./components/HeroPrompt";
import LimitedOffer from "./components/LimitedOffer";
import ThreeSteps from "./components/ThreeSteps";
import { usePromptBuilder } from "./store";
import { useHomeFlow } from "./utils";

export default function Index() {

    const { step, setStep } = useHomeFlow();

    const { setResponse } = useAiPromptResponse();
    const setPromptBuilder = usePromptBuilder(state => state.set);

    useEffect(() => {
        return () => {
            setPromptBuilder('prompt', "");
            setStep('create-design');
            setResponse(null);
        }
    }, [setPromptBuilder]);

    return (
        <HomeLayoutV2 hasFooter={true}>
            <Head title="Home" />

            <Card className="w-full shadow-xl rounded-2xl bg-[#f6f5f5] mx-auto">
                <CardHeader className="w-full flex justify-between md:flex-row flex-col items-center">
                    <Link href={route('home')}>
                        <ApplicationLogo className="max-w-[200px]" />
                    </Link>
                    <CodeFinder />
                </CardHeader>

                <CardHeader className="w-full py-2 flex md:gap-4 gap-2 flex-row md:justify-end justify-center items-center">
                    <HeaaderV2 />
                </CardHeader>

                <Separator />
                <CardContent >
                    {step === 'create-design' && <HeroPrompt className="max-w-7xl mx-auto w-full" />}
                    {step === 'choose-predesign' && <HeroChoosePredesign />}
                </CardContent>
            </Card>

            <ThreeSteps />

            <BestSellers />

            <LimitedOffer />

            <CategoriesPart />

            <CollectionPart />

        </HomeLayoutV2>
    );
}
