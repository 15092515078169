import { Button } from "@/Components/ui/button";
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuTrigger
} from "@/Components/ui/dropdown-menu";
import { navigate } from "@/Hooks/Functions";
import { cn } from "@/lib/utils";
import ShoppingCart from "@/Modules/ShoppingCart";
import { homeLinks } from "@/Pages/HomeV2/utils";
import { Icon } from "@iconify/react";
import { Link, usePage } from "@inertiajs/react";
import UserPopover from "./UserPopover";
import NotificationModal from "@/Modules/NotificationModal";


export default function HeaaderV2({ className }) {
    const { auth } = usePage().props;

    return (
        <div className={cn("md:w-fit w-full p-1 flex md:gap-2 gap-3 flex-row justify-center items-center", className)}>
            {homeLinks.map((link, index) => (
                <Link key={index} href={link.route} className="text-sm md:inline-flex hidden font-semibold text-primary hover:text-cyan-600 md:px-3 px-1 py-2">{link.label}</Link>
            ))}
            <DropdownMenu >
                <DropdownMenuTrigger className="md:hidden bg-theme text-white px-4 py-[4px] rounded-full inline-flex md:mr-0 mr-auto items-center gap-2 w-fit group">
                    <Icon icon="game-icons:hamburger-menu" className="text-xl group-hover:text-gray-600" />
                    <span className="group-hover:text-gray-600 text-sm font-normal">Menu</span>
                </DropdownMenuTrigger>
                <DropdownMenuContent>
                    {homeLinks.map((link, index) => (
                        <DropdownMenuItem key={index} onClick={() => navigate(link.route)}>{link.label}</DropdownMenuItem>
                    ))}
                </DropdownMenuContent>
            </DropdownMenu>

            {!auth?.user ? <>
                <Link href={route('login', { redirect_uri: window.location.origin })}><Button className="rounded-full" size="xs">Sign In</Button></Link>
                <Link href={route('register', { redirect_uri: window.location.origin })}><Button className="rounded-full" size="xs">Sign Up</Button></Link>
            </> : <>
                <ShoppingCart iconClass="text-primary" />
                <NotificationModal />
                <UserPopover />
            </>}
        </div>
    )
}
