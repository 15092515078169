
import AccordionCarousel from "@/Components/extension/accordion-carousel"
import { useProductCollections } from "@/Hooks/CollectionsHook"
import { navigate } from "@/Hooks/Functions";
import { useCallback, useMemo } from "react"


export default function CollectionPart() {

    const { collections } = useProductCollections();

    const list = useMemo(() => collections?.map(i => ({ image: i.image_url, title: i.name })) || [], [collections]);

    const onClick = useCallback((item) => {
        navigate(route('home.predesign-products-collections', { collection: item.slug }))
    }, [])

    return <div className="w-full mt-8 rounded-2xl flex flex-col justify-center items-center  bg-transparent">
        <AccordionCarousel list={list} className="h-[50vh] bg-transparent rounded-2xl" onClick={onClick} />
    </div>
}
