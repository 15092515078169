import { Button } from "@/Components/ui/button";
import React, { useState } from "react";

function TruncatedText({ children, maxWords = 100, className = "" }) {
    const [isExpanded, setIsExpanded] = useState(false);

    const originalTextContent = extractTextContent(children);
    const originalWordsCount = originalTextContent.split(" ").length;
    const isOverMaxWords = originalWordsCount > maxWords;

    const truncatedText = isExpanded
        ? originalTextContent
        : isOverMaxWords
            ? truncateText(originalTextContent, maxWords)
            : originalTextContent;
    const hideButton = originalWordsCount <= maxWords;

    function toggleText() {
        setIsExpanded(!isExpanded);
    }

    function extractTextContent(children) {
        let textContent = "";
        React.Children.forEach(children, (child) => {
            if (typeof child === "string") {
                textContent += child;
            } else if (Array.isArray(child)) {
                textContent += extractTextContent(child);
            }
        });
        return textContent;
    }

    function truncateText(text, maxWords) {
        const words = text.split(" ");
        if (words.length > maxWords) {
            return words.slice(0, maxWords).join(" ") + "...";
        }
        return text;
    }

    return (
        <div>
            <div className={className}>
                {truncatedText}
                {!hideButton && (
                    <Button
                        onClick={toggleText} size="xs" variant="link"
                        className="hover:underline text-cyan-500 text-xs"
                    >
                        {isExpanded ? "View Less" : "View More"}
                    </Button>
                )}
            </div>
        </div>
    );
}

export default TruncatedText;
